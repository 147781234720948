import React, {useState, useCallback} from 'react';
import Cookies from 'js-cookie';
import {useLocation} from '@reach/router';
import {Helmet} from 'react-helmet';
import * as ReactGA from 'react-ga';
import Navbar from '../../../components/Showcase/Navbar';
import Footer from '../../../components/Showcase/Footer';
import 'react-multi-carousel/lib/styles.css';
import '../../../assets/css/showcase-ar-vr.css';

import HeaderSection from '../../../components/Showcase/Sections/HeaderSection';
import WhyArSection from '../../../components/Showcase/Sections/WhyArSection';
import IndustriesSection from '../../../components/Showcase/Sections/Industry/IndustriesSection';
import HowWeWorkSection from '../../../components/Showcase/Sections/HowWeWorkSection';
import YourOptionsSection from '../../../components/Showcase/Sections/YourOptionsSection';
import AwardsSection from '../../../components/Showcase/Sections/AwardsSection';
import OurExpertiseSection from '../../../components/Showcase/Sections/OurExpertiseSection';
import PortfolioSection from '../../../components/Showcase/Sections/PortfolioSection';
import ContactUsSection from '../../../components/Showcase/Sections/ContactUsSection';
import {
  NAVBAR,
  HEADER,
  WHY_AR,
  HOW_WE_WORK,
  INDUSTRIES,
  YOUR_OPTIONS,
  OUR_EXPERTISE,
  FEATURED_PROJECTS,
  CONTACT_US,
  FOOTER,
  AWARDS,
} from '../../../components/Showcase/content/no';
import CookieBanner from '../../../components/Shared/CookieBanner';

function ARVR() {
  const {pathname} = useLocation();
  const [, setCookieState] = useState(false);

  const acceptCookieHandler = useCallback(() => {
    Cookies.set('permission-cookies', 'perm-granted', {secure: true}, {expires: 150});
    setCookieState(true);
  }, []);
  React.useEffect(() => {
    ReactGA.set({
      title: 'Showcase.ARVR',
    });
    ReactGA.pageview(pathname);
  }, [pathname]);

  return (
    <div lang="no">
      <Helmet>
        <title>Augmented Reality – Tintash Solutions</title>
      </Helmet>
      <header>
        <Navbar NAVBAR={NAVBAR} />
        <HeaderSection HEADER={HEADER} />
      </header>
      <WhyArSection WHY_AR={WHY_AR} />
      <HowWeWorkSection HOW_WE_WORK={HOW_WE_WORK} />
      <IndustriesSection INDUSTRIES={INDUSTRIES} />
      <YourOptionsSection YOUR_OPTIONS={YOUR_OPTIONS} />
      <OurExpertiseSection OUR_EXPERTISE={OUR_EXPERTISE} />
      <AwardsSection AWARDS={AWARDS} />
      <PortfolioSection FEATURED_PROJECTS={FEATURED_PROJECTS} />
      <ContactUsSection CONTACT_US={CONTACT_US} />
      {!Cookies.get('permission-cookies') && <CookieBanner acceptHandler={acceptCookieHandler} />}
      <Footer FOOTER={FOOTER} />
    </div>
  );
}

export default ARVR;
