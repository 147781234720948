export const NAVBAR = {
  NAV_LINKS: [
    {href: '#why-ar', title: 'Hvorfor AR'},
    {href: '#how-we-work', title: 'Hvordan vi jobber'},
    {href: '#your-options', title: 'Dine alternativer'},
    {href: '#expertise', title: 'Ekspertise'},
    {href: '#portfolio', title: 'Portefølje'},
    {href: '#contact-us', title: 'Kontakt oss'},
  ],
};

export const HEADER = {
  title: 'AR – En ny dimensjon til kundeopplevelsen',
  description:
    'Vi hjelper bedrifter med effektiv markedsføring og økt kundeengasjement gjennom digitale virkemidler.',
  buttonOneText: 'Planlegg prøveperiode',
  buttonTwoText: 'Se det i aksjon!',
  scanMeImg: require('../../../assets/images/showcase/ar-vr/scan-me-no.png').default,
};

export const WHY_AR = {
  title: 'Augmented Reality – hvorfor?',
  description:
    'AR gir deg mulighetn til å øke det digitale engasjementet med publikum og øke din digitale tilstedeværelse for bedre salgskonverteringer',
  CARDS: [
    {
      imgSrc: require('../../../assets/images/showcase/ar-vr/why-ar-1.png').default,
      title: 'Visualisering av produkter og omgivelser',
      description: 'AR hjelper deg med å visualisere produkter i rette omgivelser',
      checklist: [
        'Modeller og vis 3D-design av produktene dine gjennom en AR-tilpasset plattform',
        'Dekorer og innred et rom med AR-visualiseringsverktøy',
      ],
    },
    {
      imgSrc: require('../../../assets/images/showcase/ar-vr/why-ar-2.png').default,
      title: 'Engasjer kundene dine!',
      description:
        'Øk kundeengasjementet for merkevaren din gjennom visuelt og interaktivt innhold.',
      checklist: [
        'Engasjer kundene dine gjennom imponerende AR-teknologi',
        'Forny markedsføringsstrategien din gjennom spennende interaksjonsmuligheter med kunden',
        'Kjør innovative markedsførings- og salgskampanjer',
        'Rask og enkel distribusjon i kombinasjon med tradisjonell markedsføring',
      ],
      ltr: false,
    },
    {
      imgSrc: require('../../../assets/images/showcase/ar-vr/why-ar-3.png').default,
      title: 'Øk salget!',
      description: 'Benytt deg av fremtidens teknologi for å øke produktsalget.',
      checklist: [
        'Få flere kunder til å gjennomføre et kjøp ved å tilby AR-aktiverte produkter',
        'Forenkler og forbedrer netthandel',
      ],
    },
    {
      imgSrc: require('../../../assets/images/showcase/ar-vr/why-ar-4.png').default,
      title: 'Utdanning og opplæring',
      description:
        'Gjør utdanning og opplæring engasjerende gjennom interaktive AR- og VR-opplevelser.',
      checklist: [
        'Tilrettelegg læring gjennom visuelt innhold og historiefortelling',
        'Effektiviser og forbedre virtuell opplæring gjennom interaktivt AR-basert innhold',
      ],
      ltr: false,
    },
  ],
};

export const HOW_WE_WORK = {
  title: 'Slik jobber vi',
  description:
    'Vi samarbeider tett med kunden for å sikre at AR- og VR-opplevelser stemmer overens med dine forventinger og forretningsmål',
  CARDS: [
    {
      title: 'Strategi og markedstilnærming',
      description:
        'Vi hjelper deg med å gjennomføre ideene dine ved bruk av de beste løsningene for et optimalt resultat.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/how-we-work-1.svg').default,
    },
    {
      title: 'Forberedelse',
      description:
        'Vi oversetter ideene dine til tekniske spesifikasjoner. Vi bygger gjerne 3D-modeller, animasjoner og annet nødvendig innhold for en AR- eller VR-opplevelse.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/how-we-work-2.svg').default,
    },
    {
      title: 'Utvikling',
      description: 'Vi bruker en smidig og effektiv metode under utvikling av løsningen.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/how-we-work-3.svg').default,
    },
    {
      title: 'Live distribusjon',
      description: 'Vi distribuerer løsningen og oppdaterer etter behov.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/how-we-work-4.svg').default,
    },
  ],
};

export const INDUSTRIES = {
  title: 'Se AR i aksjon',
  description:
    'Vårt rammeverk er skreddersydd for å sikre at AR / VR opplever linje med virksomhetens mål for maksimal avkastning, på tvers av alle bransjer',
  buttonText: 'Se det i aksjon!',
  scanMeImg: require('../../../assets/images/showcase/ar-vr/scan-me-top-no.png').default,
  FURNITURE: {
    title: 'Møbler',
    color: '#9E9EA6',
    images: [
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-furniture-1.png').default,
        title: 'Furniture Idustry App V1',
        label: 'Velg et produkt',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-furniture-2.png').default,
        title: 'Furniture Idustry App V2',
        label: 'Velg en overflate',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-furniture-3.png').default,
        title: 'Furniture Idustry App V3',
        label: 'Plasser elementet',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-furniture-4.png').default,
        title: 'Furniture Idustry App V4',
        label: 'Tilpass og kjøp',
      },
    ],
  },
  CLOTHING: {
    title: 'Klær',
    color: '#9C667A',
    images: [
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-clothing-1.png').default,
        title: 'Clothing Idustry App V1',
        label: 'Velg et produkt',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-clothing-2.png').default,
        title: 'Clothing Idustry App V2',
        label: 'Plasser elementet',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-clothing-3.png').default,
        title: 'Clothing Idustry App V3',
        label: 'Roter til visning',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-clothing-4.png').default,
        title: 'Clothing Idustry App V4',
        label: 'Legg i handlekurv',
      },
    ],
  },
  FOOD_AND_BEVERAGE: {
    title: 'mat og Drikke',
    color: '#707E70',
    images: [
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-food-beverage-1.png')
          .default,
        title: 'Food & Beverage Idustry App V1',
        label: 'Åpne kameraet',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-food-beverage-2.png')
          .default,
        title: 'Food & Beverage Idustry App V2',
        label: 'Zoom på produktet',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-food-beverage-3.png')
          .default,
        title: 'Food & Beverage Idustry App V3',
        label: 'Vent med å skanne',
      },
      {
        imgSrc: require('../../../assets/images/showcase/ar-vr/industries-food-beverage-4.png')
          .default,
        title: 'Food & Beverage Idustry App V4',
        label: 'Vis detaljer',
      },
    ],
  },
};

export const YOUR_OPTIONS = {
  title: 'Hvilke alternativ har du?',
  description: 'Vi har fleksible løsninger for å møte dine forretningsbehov.',
  CARDS: [
    {
      title: 'Apputvikling',
      description: 'En mobilapplikasjon kan brukes for salg eller internt i bedriften din.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/your-options-1.svg').default,
    },
    {
      title: 'Web AR/VR-løsning',
      description: 'AR kan inkluderes via nettsiden din til bruk for kunde ellet internt.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/your-options-2.svg').default,
    },
    {
      title: 'Eksternt team',
      description:
        'Vi kan tilby et eksternt team til å jobbe med din tekniske avdeling på spesifikke oppgaver eller prosjekter.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/your-options-3.svg').default,
    },
  ],
};

export const AWARDS = {
  title: 'Annerkjennelser',
};

export const OUR_EXPERTISE = {
  title: 'Vår ekspertise',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exe',
  CARDS: [
    {
      title: 'Full stack utvikling',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/our-expertise-1.svg').default,
    },
    {
      title: 'Opplevelsesdesign',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/our-expertise-2.svg').default,
    },
    {
      title: 'Produktledelse',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/our-expertise-3.svg').default,
    },
    {
      title: 'Multi-plattform integrasjon',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
      imgSrc: require('../../../assets/images/showcase/ar-vr/our-expertise-4.svg').default,
    },
  ],
};

export const FEATURED_PROJECTS = [
  {
    id: 'decorist',
    caseStudyUrl: '/portfolio/decorist-room-builder',
    featuredImageSrc: require('../../../assets/images/showcase/ar-vr/featured-projects/featured-images/decorist.png')
      .default,
    logoSrc: require('../../../assets/images/showcase/ar-vr/featured-projects/logos/decorist.png')
      .default,
    projectDescription: 'En online interiørdesigntjeneste som matcher deg.',
    projectName: 'decorist',
    solutionCategory: 'Webutvikling',
    linkText: 'Se casestudie',
  },
];

export const CONTACT_US = {
  title: 'Prøv oss!',
  description: 'Vi lager 3D modeller og implemeterer AR-teknologien på nettstedet ditt.',
  buttonText: 'Ta kontakt',
};

export const FOOTER = {
  description:
    'Safir Solutions jobber med digital transformasjon ved å tilby rådgivningstjenester og digitale produkter. Vi hjelper deg med å vurdere behovet og omfanget av digitalisering og å forberede deg på organisatoriske endringer som følger med det. Våre digitale produkter er designet med et brukerperspektiv.',
  copyright: `Copyright ${new Date().getFullYear()} Safir Solutions © All Rights Reserved`,
  contact: 'kontakt',
  mailUs: 'E-post',
  contactUs: 'kontakt oss',
};
